import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import CallToAction from "../components/CallToAction"
import Button from "../components/Button"
import Heading from "../components/Heading"
import "../styles/services.css"
import Layout from "../components/Layout.js"
import Seo from "../components/Seo.js"

export const ServicesPage = () => {
  return (
    <Layout>
      <Seo
        title="What We Do"
        description="We balance creativity and commercial thinking to unlock the potential of ambitious businesses and brands. From brand strategy, website design and web application developemnt."
      />
      <Heading>What We Do</Heading>
      <section className="full-width-halves">
        <div className="half shaded">
          <div className="inner">
            <h2>Web Design</h2>
            <p>
              Web design is our bread and butter. We’ve been in the game for
              almost 20 years now, so it’s fair to say we know a thing or two
              about our craft.
            </p>
            <p>
              Building an effective website that achieves the owners goals is an
              art form, one which we’ve spent the better part of the past two
              decades perfecting. From landing page aesthetics, call to action
              placement through to page speed optimization we carefully plan
              everything, leaving nothing to chance.
            </p>
            <p>
              We primarily develop websites using either WordPress or Gatsby,
              but have experience with a vast array of different CMS and
              eCommerce systems. It’s important that we select the right system
              for your business, so we’ll likely spend a bit of time discussing
              with you which system is the right fit for your needs.
            </p>
            <Button link="/contact">Get in touch</Button>
          </div>
        </div>
        <div className="half image">
          <StaticImage
            placeholder="blurred"
            src="../images/canmore-web-design.jpg"
            alt="Banff Townsite in the summer months"
          />
        </div>
      </section>

      <section className="full-width-halves">
        <div className="half image">
          <StaticImage
            placeholder="blurred"
            src="../images/banff-web-design.jpg"
            alt="Bow Valley train and river"
          />
        </div>
        <div className="half">
          <div className="inner">
            <h2>eCommerce</h2>
            <p>
              If the pandemic has taught business people anything, it’s that it
              is critical to have an online sales channel to enable your
              business to continue to thrive regardless of restrictions.
            </p>
            <p>
              At Rocky Mountain Web we’ve been building online stores for a long
              time so we know a thing or two about working with businesses who
              want to sell effectively online. No two businesses are quite the
              same and neither should their online sales channel strategies.
            </p>
            <p>
              That’s why we work with businesses to carefully integrate their
              online channel with their existing brick and mortar operations.
            </p>
            <p>
              From site design, to online payment processors and fulfillment
              strategies we’ve got you covered when it comes to selling products
              and services online.
            </p>
            <Button link="/contact">Get in touch</Button>
          </div>
        </div>
      </section>

      <section className="full-width-halves">
        <div className="half shaded">
          <div className="inner">
            <h2>Software Development</h2>
            <p>
              Got a great mobile app idea? Maybe your business needs a custom
              piece of software developed to achieve a particular goal?
            </p>
            <p>Rocky Mountain Web can bring that vision to fruition.</p>
            <p>
              Throughout the years we’ve worked on numerous software projects
              for our clients and in doing so have acquired a multi-faceted
              skillset that enables us to get the job done on-time, within
              budget and most importantly, meeting client expectations.
            </p>
            <p>
              Good software development starts with thorough requirements
              analysis, proceeds with an iterative feedback loop and concludes
              with exhaustive end user testing.
            </p>
            <p>
              We’d love for you to chat with us about what you’re wanting to
              achieve today!
            </p>
            <Button link="/contact">Get in touch</Button>
          </div>
        </div>
        <div className="half image">
          <StaticImage
            placeholder="blurred"
            placeholder="blurred"
            src="../images/banff-social-media-marketing.jpg"
            alt="Peyto Lake in the winter"
          />
        </div>
      </section>

      <section className="full-width-halves">
        <div className="half image">
          <StaticImage
            placeholder="blurred"
            src="../images/bow-valley-web-design.webp"
            alt="Cave and Basin"
          />
        </div>
        <div className="half">
          <div className="inner">
            <h2>Microsoft 365 / Google Workspace</h2>
            <p>
              Whether you’re looking for someone to develop an advanced
              Microsoft Power Automate script to automate processes within your
              business, or just someone to help migrate your emails to Google
              Workspace, we can help!
            </p>
            <p>
              We have extensive experience leveraging cloud based productivity
              offerings to help businesses achieve goals and unlock previously
              unknown potential.
            </p>
            <p>Technologies we specialize in:</p>
            <ul>
              <li>SharePoint Online</li>
              <li>Power Automate</li>
              <li>MS Power Apps</li>
              <li>Exchange Online</li>
              <li>Google Workspace</li>
            </ul>
            <Button link="/contact">Get in touch</Button>
          </div>
        </div>
      </section>

      <CallToAction
        heading="Contact Us"
        body="With such a wide variety of services, we've likely got your needs covered. So why not get in touch today?"
        buttonText="Contact Us"
        bgColor="rgb(36, 110, 185)"
        link="contact"
      />
    </Layout>
  )
}

export default ServicesPage
